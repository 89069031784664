import { Suspense } from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import './helpers/i18next'
import routes from "./routes/routes";
import routesPaths from "./routes/routesPaths";
import Layout from "./components/Layout";
import Preloader from "./components/Preloader";

export default function App() {
  return (
    <Layout>
      <Suspense fallback={<Preloader />}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route path='*' element={<Navigate to={routesPaths.main} replace />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}
