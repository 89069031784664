import { Link } from 'react-router-dom'
import styled from "styled-components";
import { colors } from '../../helpers/consts'

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${colors.lightBlue};
  padding: 2.6rem 0 1.6rem;
  margin-top: auto;

  @media (min-width: 48em) {
    padding: 4rem 0 2rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 1rem;
  border-top: 1px solid #b6becf;

  @media (min-width: 28em) {
    padding-top: 1.6rem;
  }

  @media (min-width: 58em) {
    padding-top: 2.6rem;
  }
`;

export const BottomInfoText = styled.p`
  font-size: 1.2rem;
  color: #454545;
  padding: 0.4rem 0;
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;

  @media (min-width: 58em) {
    flex-wrap: nowrap;
  }
`;

export const LangContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const LangText = styled.p`
  font-size: 1.3rem;
  color: ${colors.alternativeDarkBlue};
  margin-right: 0;

  @media (min-width: 58em) {
    margin-right: 1rem;
  }
`;

export const BottomLink = styled.a.attrs({
  target: '_blank',
})`
   font-size: 1.3rem;
   color: ${colors.black};
   padding: 0.4rem 0;
   transition: color 0.3s ease-out;

   &:hover,
   &:focus {
    color: ${colors.orange};
   }
`;

export const BottomInnerLink = styled(Link)`
   font-size: 1.3rem;
   color: ${colors.black};
   padding: 0.4rem 0;
   transition: color 0.3s ease-out;

   &:hover,
   &:focus {
    color: ${colors.orange};
   }
`;

export const MainContent = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: #CBCBCB 1px solid;
  padding-bottom: 2rem;

  @media (min-width: 58em) {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  @media (min-width: 58em) {
    padding-bottom: 4rem;
  }
`;

export const LogoTitle = styled.h2`
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 100%;
  color: ${colors.alternativeDarkBlue};
  margin-left: 1.2rem;

  @media (min-width: 58em) {
    font-size: 3.2rem;
    margin-left: 1.6rem;
  }
`;

export const Logo = styled.img.attrs({
  alt: "",
})`
  min-width: 2.7rem;
  max-width: 2.7rem;

  @media (min-width: 58em) {
    min-width: 4rem;
    max-width: 4rem;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  transition: box-shadow 0.2s ease-out;
  justify-self: flex-start;
  min-height: 3.2rem;
  margin-bottom: 1.2rem;

  @media (min-width: 58em) {
    margin-bottom: 2rem;
  }
`;

export const ContactsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1.6rem;

  @media (min-width: 30em) {
    padding-bottom: 0;

    &:nth-of-type(2n - 1) {
      padding-right: 1rem;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      padding-bottom: 1rem;
    }
  }

  @media (min-width: 58em) {
    align-items: flex-start;
    text-align: left;

    &:not(:last-of-type) {
      padding-right: 1rem;
    }
  }
`;

export const AppsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1.4rem;

  @media (min-width: 58em) {
    align-items: flex-start;
    text-align: left;
  }
`;

export const Apps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  max-width: 100%;

  @media (min-width: 30em) {
    flex-direction: row;
  }
`;

export const App = styled.a.attrs({
  target: '_blank'
})`
  max-width: 18rem;
  height: 5.2rem;
  transition: transform 0.2s ease-out;

  &:hover,
  &:focus {
    transform: scale(1.06);
  }

  :first-of-type {
    margin-bottom: 1.2rem;
  }

  @media (min-width: 30em) {
    max-width: 20rem;
    height: 5.8rem;

    :first-of-type {
      margin-right: 1.2rem;
      margin-bottom: 0;
    }
  }
`;

export const ContactsTitle = styled.h3`
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  padding-left: 0.6rem;
  color: ${colors.black};
`;

export const AddressText = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1.4rem;
  padding-left: 0.6rem;
  color: ${colors.black};
`;

export const ContactsLink = styled.a.attrs({
  target: '_blank'
})`
  padding: 0.3rem 0.6rem;
  transition: transform 0.2s ease-out;

  &:hover,
  &:focus {
    transform: scale(1.2);
  }

  svg {
    max-width: 3.7rem;
    max-height: 3.7rem;
  }
`;

export const FooterLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media (min-width: 58em) {
    align-items: flex-start;
    margin-bottom: 0rem;
    max-width: 50%;
  }

  > p {
    font-size: 1.3rem;

    @media (min-width: 58em) {
      font-size: 1.4rem;
      text-align: left;
    }
  }
`;

export const PageContactsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1.4rem;
  padding-left: 0rem;
  padding-bottom: 2rem;
  width: 100%;

  @media (min-width: 58em) {
    width: 50%;
    padding-left: 1rem;
    padding-bottom: 0;
    gap: 2rem;
  }

  h3 {
    padding-left: 0rem;
  }
`;

export const PageContactColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-basis: 46%;

  @media (min-width: 38em) {
    flex-basis: 23%;
  }

  @media (min-width: 58em) {
    flex-basis: 46%;
  }
`;
