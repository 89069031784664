import { useState, useCallback, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"

import { actions } from '../redux/global/globalSlice';
import { getLang } from '../redux/global/globalSelectors';
import { useOnClickOutside } from '../helpers/hooks'

import { colors } from '../helpers/consts'
import { apear } from '../App.styled'

const CustomSelect = styled.div`
 position: relative;
`
const SelectButton = styled.button.attrs({
  type: "button",
})`
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 0.4rem;
  min-width: 6.1rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ isBottom }) => isBottom ? colors.alternativeDarkBlue : colors.white};
  text-transform: uppercase;
  margin-left: 0.8rem;
  transition: color 0.2s ease-out, box-shadow 0.2s ease-out;

  &::after {
    display: inline-block;
    margin-left: 0.5rem;
    content: "";
    border-top: 4px solid ${({ isBottom }) => isBottom ? colors.alternativeDarkBlue : colors.white};
    border-right: 4px solid transparent;
    border-bottom: 0;
    border-left: 4px solid transparent;
    transition: border-top 0.2s ease-out;
  }

  &:hover,
  &:focus {
    color: ${colors.orange};

    &::after {
    border-top: 4px solid ${colors.orange};
  }
  }
`;

const CustomSelectMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 30;
  background-color: ${colors.white};
  ${({ isBottom }) => isBottom ? 'bottom: 3.6rem;' : 'top: 3.6rem;'}
  right: 0;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  max-height: 12rem;
  overflow-y: auto;
  min-width: 6rem;
  padding: 0.6rem 0;
  animation: ${apear} 0.2s ease-out forwards;
`;

const CustomSelectMenuItem = styled.button.attrs({
  type: 'button'
})`
  width: 100%;
  padding: 0.4rem 1.6rem;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #212529;
  text-transform: uppercase;
  background-color: ${colors.white};
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  &:hover,
  &:focus {
    color: #33343a;
    background-color: #e9ecef;
    box-shadow: none;
  }
  &:active {
    color: ${colors.white};
    background-color: #0d6efd;
  }
`;

const languages = ["en", "ru", "de"];
const acceptLang =
  navigator?.language?.slice(0, 2) === "ru" ||
  navigator?.language?.slice(0, 2) === "de" ||
  navigator?.language?.slice(0, 2) === "en"
    ? navigator.language.slice(0, 2)
    : "en";

export default function Language({ isBottom }) {
  const dispatch = useDispatch()
  const currentLang = useSelector(getLang)

  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => setIsOpen(false)
  const setLang = useCallback((val) => dispatch(actions.setLang(val)), [dispatch]);
  useOnClickOutside(ref, closeModal)

  const changeLang = useCallback((lang) => {
    if (localStorage.getItem("lang") === lang) {
      return closeModal();
    }

    setLang(lang)
    localStorage.setItem("lang", lang)
    closeModal();
  }, [setLang])

  const setLanguage = useCallback(() => {
    const existLang = localStorage.getItem("lang")
    if (
      existLang &&
      existLang !== "ru" &&
      existLang !== "de" &&
      existLang !== "en"
    ) {
      setLang(acceptLang)
      return localStorage.setItem("lang", acceptLang)
    }
    if (existLang) {
      return setLang(existLang)
    }
    if (!existLang) {
      localStorage.setItem("lang", acceptLang)
      setLang(acceptLang)
    }
  }, [setLang]);

  useEffect(() => {
    setLanguage();
  }, [setLanguage]);

  return (
    <CustomSelect ref={ref}>
      <SelectButton 
        onClick={() => setIsOpen(!isOpen)}
        isBottom={isBottom}
      >
        {currentLang}
      </SelectButton>
      

      {isOpen && (
        <CustomSelectMenu isBottom={isBottom}>
          {languages.filter((lang) => lang !== currentLang).map(
            (lang) => (
              <CustomSelectMenuItem
                key={lang}
                onClick={() => changeLang(lang)}
              >
                {lang}
              </CustomSelectMenuItem>
            )
          )}
        </CustomSelectMenu>
      )}
    </CustomSelect>
  );
}
